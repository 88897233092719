<template>
  <div :class="{'ly-is-full':isFull}">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
        <el-form-item label="创建用户：">
          <el-input size="default" v-model.trim="formInline.creator_name" maxlength="60"  clearable placeholder="创建用户" @change="search" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="敏感词句：">
          <el-input size="default" v-model.trim="formInline.sensitiveword" maxlength="60"  clearable placeholder="敏感词句" @change="search" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="创建时间：">
          <el-date-picker
              style="width:350px"
              v-model="timers"
              type="datetimerange"
              @change="timeChange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="isShowBtn('wordManage','敏感过滤','Search')">查询</el-button></el-form-item>
        <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">重置</el-button></el-form-item>
        <el-form-item label=""><el-button  @click="addAdmin" type="primary" icon="Plus" v-show="isShowBtn('wordManage','敏感过滤','Create')">新增</el-button></el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table  :height="'calc('+(tableHeight)+'px)'" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
        <el-table-column type="index" width="80" align="center" label="序号">
          <template #default="scope">
            <span v-text="getIndex(scope.$index)"></span>
          </template>
        </el-table-column>
        <el-table-column min-width="100" prop="creator_name" label="创建用户"></el-table-column>
        <el-table-column min-width="180" prop="sensitiveword" label="过滤敏感词句"></el-table-column>

        <el-table-column min-width="120" prop="create_datetime" label="创建时间"></el-table-column>
        <el-table-column min-width="120" prop="update_datetime" label="更新时间"></el-table-column>

        <el-table-column label="操作" fixed="right" width="80">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
              <div @click="setFull">
                <el-tooltip content="全屏" placement="bottom">
                  <el-icon ><full-screen /></el-icon>
                </el-tooltip>
              </div>
            </div>
          </template>
          <template #default="scope">
            <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="isShowBtn('wordManage','敏感过滤','Update')">编辑</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row,'delete')" v-show="isShowBtn('wordManage','敏感过滤','Delete')">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <add-word ref="addWordFlag" @refreshData="getData"></add-word>
<!--    <ota-detail ref="otaDetailFlag"></ota-detail>-->
  </div>
</template>
<script>
import addWord from "./components/addWord";
import Pagination from "@/components/Pagination";
import {dateFormats,getTableHeight} from "@/utils/util";
import {
  Wordset,
  WordsetDelete
} from '@/api/api'
export default {
  components:{
    Pagination,
    addWord,
  },
  name:'wordManage',
  data() {
    return {
      isFull:false,
      tableHeight:500,
      loadingPage:true,
      formInline:{
        page: 1,
        limit: 10,
      },
      defaultImg:require('../../assets/img/avatar.jpg'),
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList:[
        {id:1,name:'正常'},
        {id:0,name:'禁用'}
      ],
      identityList:[
        {id:0,name:'普通用户'},
        {id:1,name:'会员'},
      ],
      timers:[],
      tableData:[]
    }
  },

  methods:{
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page-1)*this.pageparm.limit + $index +1
    },
    //监听switch开关状态的改变
    latestStatusChanged(otainfo){
      // console.log(otainfo)
      //更新user状态信息到后端数据接口,动态数据用${}取
      var params = {
        id:otainfo.id,
      }
      OTASetLatest(params).then(async res => {
        // console.log(res)
        if (res.code !== 2000) {
          // console.log(res.data)
          return this.$message.error('更新状态失败')
        } else {
          // 先隐藏修改用户的对话框
          // this.editDialogVisible = false
          // 再重新获取用户列表, 来刷新页面
          this.getData()
          // 最后提示修改成功
          this.$message.success('更新状态成功')
        }
      })
    },
    //监听switch开关状态的改变
    defaultStatusChanged(otainfo){
      // console.log(otainfo)
      //更新user状态信息到后端数据接口,动态数据用${}取
      var params = {
        id:otainfo.id,
      }

    },
    setFull(){
      this.isFull=!this.isFull
      window.dispatchEvent(new Event('resize'))
    },
    changeStatus(row) {
      // console.log(row,'row----')
    },
    addAdmin() {
      this.$refs.addWordFlag.addWordFn(null,'新增')
    },
    handleEdit(row,flag) {
      if(flag=='edit') {
        this.$refs.addWordFlag.addWordFn(row,'编辑')
      }
      else if(flag=='detail') {
        this.$refs.otaDetailFlag.addWordFn(row,'详情')
      }

      else if(flag=='delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？',{
          closeOnClickModal:false
        }).then(res=>{
          WordsetDelete({id:row.id}).then(res=>{
            if(res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(()=>{

        })
      }
      else if(flag=="reset"){
        this.formInline = {
          page:1,
          limit: 10
        }
        this.pageparm={
          page: 1,
          limit: 10,
          total: 0
        }
        this.getData()
      }
    },

    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
    //获取列表
    async getData() {
      this.loadingPage = true
      Wordset(this.formInline).then(res => {
        this.loadingPage = false
        if(res.code ==2000) {
          this.tableData = res.data.data
          this.pageparm.page = res.data.page;
          this.pageparm.limit = res.data.limit;
          this.pageparm.total = res.data.total;
        }
      })
    },

    timeChange(val){
      if (val) {
        this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight(){
      let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
      tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
      this.tableHeight =  getTableHeight(tabSelectHeight)
    }

  },
  created() {
    this.getData()
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>
